/* eslint-disable node/no-unsupported-features/es-syntax */
'use client';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';
import {
  format as formatDate,
  isDate,
  formatDistanceToNowStrict,
} from 'date-fns';
import { fr, enUS, de, nl } from 'date-fns/locale';

const locales = { fr, en: enUS, de, nl };

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
  apiKey: process.env.NEXT_PUBLIC_LOCIZE_API_KEY,
  version: process.env.NEXT_PUBLIC_LOCIZE_VERSION,
  referenceLng: 'fr',
  allowedHosts: ['localhost'],
};

i18n
  .use(Backend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    saveMissing: !isProduction,
    pluralSeparator: '_',
  });

i18n.services.formatter.add('distanceDate', (value, lng) => {
  if (isDate(value)) {
    const locale = locales[lng];
    return formatDistanceToNowStrict(value, {
      locale,
    });
  }
});

i18n.services.formatter.add('date', (value, lng, options) => {
  if (isDate(value)) {
    const locale = locales[lng];
    return formatDate(value, options.format, { locale });
  }
});

export default i18n;
